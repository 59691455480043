import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { isEmpty } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  AboutTechSpecs,
  Carousel,
  ErrorMessage,
  FeedTimeline,
  Layout,
  ListingStatus,
  ProductionPictures,
  ReviewsStepper,
  SEO,
  SendMessage,
  SkeletonCarousel,
  SkeletonAboutTechSpecs,
  ShippingDetails,
  UploadModal,
} from 'components'
import {
  getAlertAction,
  getFeedTimelineDescription,
  INTERNAL_MESSAGE,
} from 'constants/alertConstants'
import { NOT_FOUND_ENDPOINT, SIGNUP_ENDPOINT } from 'constants/apiUrls'

import { LISTING_STATUS } from 'constants/listingConstants'

import {
  makerListingStatus,
  nextListingState,
} from 'constants/listingStatusHandler'
import { getMailMessage } from 'constants/mailMessagesHandler'
import { OFFER_STATUS } from 'constants/offerConstants'
import {
  parseDateToDateString,
  parseDateToLiteralString,
} from 'constants/utils'
import {
  createAlert,
  deleteAlert,
  getFeedAlertsByListingId,
  updateAlert,
} from 'services/AlertService'
import { getListingById, updateListingStatus } from 'services/ListingService'
import { getListingShippingInfoByListingId } from 'services/ListingShippingInfoService'
import { sendNotification } from 'services/MailService'
import {
  getAcceptedOfferByListingId,
  getOfferByListingAndUser,
  getOffers,
  updateOfferStatus,
} from 'services/OfferService'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  alignCenterXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& .MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
      height: '44px',
    },
  },
  description: {
    marginLeft: '32px',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px',
    },
    [theme.breakpoints.up(1525)]: {
      marginLeft: '0px',
    },
  },
  techSpecs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    margin: '16px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      margin: '8px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  listingAuthor: {
    color: theme.palette.text.link,
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginLeft: '12px',
  },
  listingStatus: {
    backgroundColor: theme.palette.background.gray,
    borderRadius: '4px',
    color: theme.palette.text.white,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginLeft: '12px',
    padding: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  listingSubTittle: {
    color: theme.palette.text.gray,
    fontSize: '1rem',
    fontWeight: 'bold',
    opacity: '0.8',
  },
  listingTittle: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  productionPictures: {
    paddingLeft: '0px',
    marginLeft: '48px',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '24px',
    },
    [theme.breakpoints.up(1525)]: {
      marginLeft: '8px',
    },
    '& .MuiContainer-root': {
      paddingLeft: '0',
    },
  },
  sendMessage: {
    marginLeft: '48px',
    marginRight: '48px',
  },
  shippingDetails: {
    marginLeft: '32px',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px',
    },
    [theme.breakpoints.up(1525)]: {
      marginLeft: '0px',
    },
  },
  title: {
    fontSize: '2.125rem',
    fontWeight: 'bold',
    padding: '20px 0px 0px 20px',
    lineHeight: '1.8',
    [theme.breakpoints.up(1525)]: {
      paddingLeft: 'calc(50% - 700px)',
    },
  },
  titleSkeleton: {
    padding: '20px 0px 0px 20px',
    marginBottom: '16px',
  },
  feedTimelineSkeleton: {
    width: '100%',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  trackingId: {
    width: '100%',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}))

const defaultAlertState = {
  alerts: [],
  isFetched: false,
}

const defaultState = {
  listing: {},
  isFetched: false,
}

const offerDefaultState = {
  offer: {},
  isFetched: false,
}

const defaultShippingState = {
  shippingInfo: {},
  isFetched: false,
}

const ListingPage = ({ location }) => {
  const classes = useStyles()
  const loggedUser = useSelector(state => state.userState.user)
  const isLoading = useSelector(state => state.userState.isLoading)
  const { state = {}, pathname } = location
  const [alertState, setAlertState] = useState(defaultAlertState)
  const [errorMessage, setErrorMessage] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [listingState, setListingState] = useState(defaultState)
  const [newItemPreview, setNewItemPreview] = useState({})
  const [offerState, setOfferState] = useState(offerDefaultState)
  const [openReviews, setOpenReviews] = useState(false)
  const [shippingInfoState, setShippingInfoState] = useState(
    defaultShippingState
  )
  const [loggedUserIsTheChosenMaker, setLoggedUserIsTheChosenMaker] = useState(
    false
  )

  useEffect(() => {
    if (!isLoading && !loggedUser) {
      navigate(SIGNUP_ENDPOINT)
    }
  }, [isLoading, loggedUser])

  useEffect(() => {
    const getListing = async () => {
      try {
        const listingUid = pathname.split('/').pop()
        const listing = await getListingById(listingUid)
        if (loggedUser.isMaker || loggedUser.uid === listing.owned_by) {
          setListingState({
            listing,
            isFetched: true,
          })
          setNewItemPreview({
            uid: loggedUser.uid,
            date: parseDateToDateString(new Date()),
            first_name: loggedUser.first_name,
            last_name: loggedUser.last_name,
            description: '',
          })
        } else {
          navigate(NOT_FOUND_ENDPOINT)
        }
      } catch (error) {
        setErrorMessage(error.message)
        navigate(NOT_FOUND_ENDPOINT)
      }
    }
    if (state && state.listing) {
      if (
        loggedUser &&
        (loggedUser.isMaker || loggedUser.uid === state.listing.owned_by)
      ) {
        setListingState({
          listing: state.listing,
          isFetched: true,
        })
      } else {
        navigate(NOT_FOUND_ENDPOINT)
      }
    } else {
      if (!listingState.isFetched && loggedUser) {
        getListing()
      }
    }
  }, [pathname, state, listingState, loggedUser])

  useEffect(() => {
    const getListingShippingInfo = async listingId => {
      try {
        const shippingInfo = await getListingShippingInfoByListingId(listingId)
        setShippingInfoState({
          shippingInfo,
          isFetched: true,
        })
      } catch (error) {
        setErrorMessage(error.message)
      }
    }
    if (!shippingInfoState.isFetched && listingState.isFetched && loggedUser) {
      getListingShippingInfo(listingState.listing.id)
    }
  }, [listingState, loggedUser, shippingInfoState])

  useEffect(() => {
    const getAlerts = async listingId => {
      try {
        const alerts = await getFeedAlertsByListingId(listingId)
        const parsedAlerts = alerts.map(alert => ({
          ...alert.generated_by,
          id: alert.uid,
          action: alert.action,
          date: parseDateToLiteralString(alert.created_at),
          description: alert.message
            ? alert.message
            : getFeedTimelineDescription(alert.action, alert.generated_for),
        }))
        setAlertState({
          alerts: parsedAlerts,
          isFetched: true,
          isUpdating: false,
        })
      } catch (error) {
        setErrorMessage(error.message)
      }
    }

    if (!alertState.isFetched && listingState.isFetched) {
      getAlerts(listingState.listing.id)
    }
  }, [alertState, listingState])

  useEffect(() => {
    const getOffer = async () => {
      try {
        let offer
        if (loggedUser.uid === listingState.listing.owned_by) {
          if (
            listingState.listing.status === LISTING_STATUS.OFFER_COMPLETED ||
            listingState.listing.status === LISTING_STATUS.OFFER_PENDING
          ) {
            const storeOffer = await getOffers(listingState.listing.id)
            offer = storeOffer.offers ? storeOffer.offers[0] : {}
          } else {
            offer = await getAcceptedOfferByListingId(listingState.listing.id)
          }
        } else {
          offer = await getOfferByListingAndUser(
            listingState.listing.id,
            loggedUser.uid
          )
        }
        setOfferState({
          offer,
          isFetched: true,
        })
        setLoggedUserIsTheChosenMaker(
          !isEmpty(offer) &&
            (offer.status === OFFER_STATUS.ACCEPTED ||
              offer.status === OFFER_STATUS.SUBMITTED) &&
            offer.username.uid === loggedUser.uid
        )
      } catch (error) {
        setErrorMessage(error.message)
      }
    }
    if (listingState.isFetched && loggedUser && !offerState.isFetched) {
      getOffer()
    }
  }, [listingState, loggedUser, offerState])

  const {
    category,
    country,
    description,
    designer_info: designerInfo,
    images = [],
    model_name: modelName,
    owned_by: ownedBy,
    tech_specs: techSpecs = {},
    status,
    submission_date: submissionDate,
    user_info: userInfo,
    zip_code: zipCode,
    estimated_material_per_file,
  } = listingState.listing

  const { length, print_notes: printNotes, quantity } = techSpecs

  const model = {
    category,
    material_estimations: estimated_material_per_file,
    name: modelName,
    ownedBy: designerInfo,
    submission_date: submissionDate,
    tech_specs: {
      ...techSpecs,
      depth: length,
    },
  }

  const shippingDetails = {
    country,
    printNotes,
    quantity,
    shippingAddress: shippingInfoState.shippingInfo.company_address,
    state: shippingInfoState.shippingInfo.state,
    zipCode,
  }

  const loggedUserIsMaker = loggedUser && loggedUser.isMaker
  const loggedUserIsOwner = loggedUser && ownedBy && loggedUser.uid === ownedBy

  const displayStatus = () => {
    if (loggedUserIsOwner) {
      return status
    } else if (!loggedUserIsOwner && loggedUserIsMaker) {
      return makerListingStatus(status, offerState.offer.status)
    }
    return ''
  }

  const getAlerts = async listingId => {
    setErrorMessage('')
    try {
      const alerts = await getFeedAlertsByListingId(listingId)
      const parsedAlerts = alerts.map(alert => ({
        ...alert.generated_by,
        id: alert.uid,
        action: alert.action,
        date: parseDateToLiteralString(alert.created_at),
        description: alert.message
          ? alert.message
          : getFeedTimelineDescription(alert.action, alert.generated_for),
      }))
      setAlertState({
        alerts: parsedAlerts,
        isFetched: true,
        isUpdating: false,
      })
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const generateAlert = async (nextStatus, fromMaker) => {
    const listingId = listingState.listing.id
    const alertAction = getAlertAction(nextStatus)
    const listingOwner = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      uid: ownedBy,
    }
    const {
      offer: { username },
    } = offerState
    const maker = {
      first_name: username.first_name,
      last_name: username.last_name,
      uid: username.uid,
    }
    const listing = {
      modelName,
      uid: listingId,
    }
    if (fromMaker) {
      await createAlert(maker, listingOwner, alertAction, listing)
    } else {
      await createAlert(listingOwner, maker, alertAction, listing)
    }
    await getAlerts(listingId)
  }

  const updateStatus = async (action, fromMaker) => {
    const nextStatus = nextListingState(status, action)
    const mailMessage = getMailMessage(nextStatus)
    setIsUpdating(true)
    try {
      setErrorMessage('')
      const listingId = listingState.listing.id
      await updateListingStatus(listingId, nextStatus)
      if (nextStatus === LISTING_STATUS.DELIVERED) {
        await updateOfferStatus(
          offerState.offer.id,
          OFFER_STATUS.LISTING_DELIVERED
        )
      }
      await generateAlert(nextStatus, fromMaker)
      await sendMailNotification(listingId, fromMaker, mailMessage)
      setListingState({
        listing: {
          ...listingState.listing,
          status: nextStatus,
        },
        isFetched: true,
      })
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsUpdating(false)
    }
  }

  const updateProdImages = newProdFile => {
    setListingState({
      listing: {
        ...listingState.listing,
        production_files: { ...newProdFile },
        status: LISTING_STATUS.AWAITING_APPROVAL,
      },
      isFetched: true,
    })
  }

  const addAlert = (from, to, action, messageText, newAlertId) => {
    setAlertState({
      ...alertState,
      isUpdating: true,
    })
    const newAlert = {
      ...from,
      id: newAlertId,
      action,
      date: parseDateToDateString(new Date()),
      description: messageText
        ? messageText
        : getFeedTimelineDescription(action, to),
    }
    const newAlertList = [...alertState.alerts, newAlert]
    setAlertState({
      alerts: newAlertList,
      isFetched: true,
      isUpdating: false,
    })
  }

  const sendMessage = async messageText => {
    const listingId = listingState.listing.id
    const listing = {
      modelName,
      uid: listingId,
    }

    const {
      offer: { username },
    } = offerState

    const maker = {
      first_name: username.first_name,
      last_name: username.last_name,
      uid: username.uid,
    }

    try {
      if (loggedUserIsOwner) {
        const createdBy = {
          first_name: loggedUser.first_name,
          last_name: loggedUser.last_name,
          uid: loggedUser.uid,
        }
        const mailMessage = `${loggedUser.first_name} ${loggedUser.last_name}
                            left you a comment on this listing`
        const newAlertId = await createAlert(
          createdBy,
          maker,
          INTERNAL_MESSAGE,
          listing,
          messageText
        )
        await sendMailNotification(listingId, false, mailMessage)
        addAlert(createdBy, maker, INTERNAL_MESSAGE, messageText, newAlertId)
        setNewItemPreview({
          ...newItemPreview,
          description: '',
        })
      } else if (loggedUserIsTheChosenMaker) {
        const createdFor = {
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          uid: ownedBy,
        }
        const mailMessage = `${loggedUser.first_name} ${loggedUser.last_name}
                            left you a comment on your listing`
        const newAlertId = await createAlert(
          maker,
          createdFor,
          INTERNAL_MESSAGE,
          listing,
          messageText
        )
        await sendMailNotification(listingId, true, mailMessage)
        addAlert(maker, createdFor, INTERNAL_MESSAGE, messageText, newAlertId)
        setNewItemPreview({
          ...newItemPreview,
          description: '',
        })
      }
    } catch (error) {
      setErrorMessage(
        'An error occurred while trying to send your message, please try again later.'
      )
    }
  }

  const handleMessageUpdate = newMessageText => {
    setNewItemPreview({
      ...newItemPreview,
      description: newMessageText,
    })
  }

  const handleDeleteMessage = async alertId => {
    let newAlerts = alertState.alerts
    setAlertState({
      ...alertState,
      isUpdating: true,
    })
    try {
      await deleteAlert(alertId)
      newAlerts = alertState.alerts.filter(alert => alert.id !== alertId)
    } catch (error) {
      setErrorMessage(
        'An error occurred while deleting the alert, please try again later.'
      )
    } finally {
      setAlertState({
        alerts: newAlerts,
        isFetched: true,
        isUpdating: false,
      })
    }
  }

  const handleUpdateMessage = async (alertId, newDescription) => {
    let newAlerts = alertState.alerts
    setAlertState({
      ...alertState,
      isUpdating: true,
    })
    try {
      await updateAlert(alertId, { message: newDescription })
      const oldAlertIndex = newAlerts.findIndex(alert => alert.id === alertId)
      const oldAlert = newAlerts[oldAlertIndex]
      newAlerts[oldAlertIndex] = {
        ...oldAlert,
        description: newDescription,
      }
    } catch (error) {
      setErrorMessage(
        'An error occurred while deleting the alert, please try again later.'
      )
    } finally {
      setAlertState({
        alerts: newAlerts,
        isFetched: true,
        isUpdating: false,
      })
    }
  }

  const sendMailNotification = async (listingId, fromMaker, message) => {
    try {
      const userId = fromMaker
        ? listingState.listing.owned_by
        : offerState.offer.username.uid
      const { email, first_name, last_name } = await getUserById(userId)
      const username = `${first_name} ${last_name}`
      const listingURL = `${process.env.GATSBY_API_URL}/listings/${listingId}`
      const modelName = listingState.listing.model_name
      await sendNotification(email, username, message, modelName, listingURL)
    } catch (error) {
      setErrorMessage(
        'An error occurred while sending the notification, please try again later.'
      )
    }
  }
  const isIntermediateState =
    listingState.listing.offerExchange === undefined ||
    (listingState.listing.offerExchange !== undefined &&
      listingState.listing.offerExchange?.status ===
        LISTING_STATUS.AWAITING_CHANGE)

  const isOwnerOrMakerDesignated =
    !isIntermediateState &&
    loggedUser &&
    (loggedUser.uid === listingState.listing.owned_by ||
      (offerState.offer &&
        offerState.offer.status === OFFER_STATUS.ACCEPTED &&
        loggedUser.uid === offerState.offer.username.uid))

  const showShippingAddress =
    !isIntermediateState &&
    isOwnerOrMakerDesignated &&
    listingState.isFetched &&
    (listingState.listing.status === LISTING_STATUS.APPROVED ||
      listingState.listing.status === LISTING_STATUS.DELIVERED ||
      listingState.listing.status === LISTING_STATUS.SHIPPED)

  const notOfferFromSlant3d =
    !isIntermediateState &&
    offerState.isFetched &&
    !offerState.offer.is_from_slant3d

  const showSendMessageInput =
    !isIntermediateState &&
    (loggedUserIsTheChosenMaker || loggedUserIsOwner) &&
    listingState.listing.status !== LISTING_STATUS.SUBMITTED &&
    notOfferFromSlant3d

  const showSlant3dMessage =
    !isIntermediateState &&
    loggedUserIsOwner &&
    offerState.isFetched &&
    offerState.offer.is_from_slant3d

  return (
    <Layout>
      <SEO title="Listing" />
      <ErrorMessage message={errorMessage} />
      {listingState.isFetched ? (
        <div className={classes.content}>
          <Grid container spacing={4}>
            <Grid container item xs={12}>
              <Grid container item xs={12} sm={8}>
                <Grid className={classes.alignCenterXs} item xs={12}>
                  <Typography
                    className={classes.listingTittle}
                    variant="caption"
                  >
                    Listing {modelName}
                  </Typography>
                  {(!isEmpty(offerState.offer) || loggedUserIsOwner) &&
                    displayStatus().length > 0 && (
                      <Typography
                        className={classes.listingStatus}
                        variant="caption"
                      >
                        {displayStatus()}
                      </Typography>
                    )}
                </Grid>
                <Grid className={classes.alignCenterXs} item xs={12}>
                  <Typography
                    className={classes.listingSubTittle}
                    variant="caption"
                  >
                    Listed by:
                  </Typography>
                  <Typography
                    className={classes.listingAuthor}
                    variant="caption"
                  >
                    {`${userInfo.first_name} ${userInfo.last_name.charAt(0)}.`}
                  </Typography>
                </Grid>
              </Grid>
              <ListingStatus
                isUpdating={isUpdating}
                listingState={listingState}
                loggedUserIsTheChosenMaker={loggedUserIsTheChosenMaker}
                offerState={offerState}
                shippingInfoState={shippingInfoState}
                setListingState={setListingState}
                setOfferState={setOfferState}
                setOpenReviews={setOpenReviews}
                updateProdImages={updateProdImages}
                updateStatus={updateStatus}
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item lg={8} sm={7} xs={12}>
                <Carousel slides={images} thumbnails={true} />
                <div className={classes.description}>{description}</div>
              </Grid>
              <Grid
                container
                item
                lg={4}
                sm={5}
                xs={12}
                className={classes.techSpecs}
              >
                <div>
                  <AboutTechSpecs model={model} createListing={false} />
                </div>
              </Grid>
            </Grid>
            {shippingInfoState.isFetched && (
              <Grid className={classes.shippingDetails} container item xs={12}>
                <ShippingDetails
                  shippingDetails={shippingDetails}
                  showShippingAddress={showShippingAddress}
                />
              </Grid>
            )}
            {listingState.listing.production_files && (
              <Grid item xs={12} className={classes.productionPictures}>
                {loggedUserIsMaker &&
                  loggedUserIsTheChosenMaker &&
                  listingState.listing.status ===
                    LISTING_STATUS.AWAITING_APPROVAL && (
                    <div>
                      <UploadModal
                        listing={listingState.listing}
                        updateProdImages={updateProdImages}
                        updateStatus={updateStatus}
                      />
                    </div>
                  )}
                <ProductionPictures
                  isMaker={loggedUserIsMaker && loggedUserIsTheChosenMaker}
                  listing={listingState.listing}
                  uploadedBy={offerState.offer.username}
                  updateProdImages={updateProdImages}
                />
              </Grid>
            )}
            {!isEmpty(offerState.offer) && openReviews && (
              <ReviewsStepper
                designId={listingState.listing.model}
                listingId={listingState.listing.id}
                makerId={offerState.offer.username.uid}
                modelName={listingState.listing.model_name}
                loggedUser={loggedUser}
                open={openReviews}
                handleClose={() => setOpenReviews(false)}
              />
            )}
            {showSlant3dMessage && (
              <Grid className={classes.sendMessage} container item xs={12}>
                <Typography
                  align="center"
                  className={clsx(classes.listingTittle, classes.fullWidth)}
                  variant="caption"
                >
                  Your listing is handled by Slant3D, it will take at least{' '}
                  {offerState.offer.production_days +
                    offerState.offer.shipping_rate.delivery_days}{' '}
                  day(s) to be delivered to the provided address.
                </Typography>
              </Grid>
            )}
            {alertState.isFetched && (
              <>
                <Grid item xs={12}>
                  <FeedTimeline
                    currentUser={loggedUser}
                    feedList={alertState.alerts}
                    loading={alertState.isUpdating}
                    newItemPreview={newItemPreview}
                    onDelete={handleDeleteMessage}
                    onEdit={handleUpdateMessage}
                  />
                </Grid>
                {showSendMessageInput && (
                  <Grid className={classes.sendMessage} container>
                    <SendMessage
                      listingStatus={listingState.listing.status}
                      pathname={pathname}
                      onClickSend={sendMessage}
                      onMessageUpdate={handleMessageUpdate}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>
      ) : (
        <div className={classes.content}>
          <Grid container spacing={4}>
            <Grid item lg={8} sm={7} xs={12}>
              <Skeleton
                animation="wave"
                height={92}
                variant="rect"
                width="100%"
              />
              <SkeletonCarousel />
            </Grid>
            <Grid item lg={4} sm={5} xs={12} className={classes.techSpecs}>
              <div>
                <div className={classes.titleSkeleton}>
                  <Skeleton
                    animation="wave"
                    height={40}
                    variant="rect"
                    width="30%"
                  />
                </div>
                <SkeletonAboutTechSpecs />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.feedTimelineSkeleton}>
                <Skeleton
                  animation="wave"
                  height={120}
                  variant="rect"
                  width="100%"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Layout>
  )
}

export default ListingPage
