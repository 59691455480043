import { LISTING_STATUS } from './listingConstants'

export const getMailMessage = listingStatus => {
  switch (listingStatus) {
    case LISTING_STATUS.IN_PROGRESS:
      return 'The production for your listing has started'
    case LISTING_STATUS.AWAITING_APPROVAL:
      return 'Images were uploaded for your listing and waiting for your approval'
    case LISTING_STATUS.APPROVED:
      return 'The production images were approved for this listing'
    case LISTING_STATUS.SHIPPED:
      return 'The item has been shipped, see the details here'
    case LISTING_STATUS.DELIVERED:
      return 'The item from this listing was received'
    default:
      return ''
  }
}
